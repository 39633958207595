import { ImageOfFurniture } from "classes/Image";

import furnitureStyle01ImageSrc from "resources/images/furniture/furniture-style01-300x130.png";
import furnitureStyle02ImageSrc from "resources/images/furniture/furniture-style02-304x88.png";
import furnitureStyle03ImageSrc from "resources/images/furniture/furniture-style03-307x152.png";
import furnitureStyle04ImageSrc from "resources/images/furniture/furniture-style04-100x80.png";
import furnitureStyle05ImageSrc from "resources/images/furniture/furniture-style05-275x164.png";

const leatherSofaTechnoLamp = new ImageOfFurniture(
  furnitureStyle01ImageSrc,
  { width: 348.8, height: 170.39 },
  { startRatio: 0.09, endRatio: 0.95 },
  0.32
);

const WoodenChairAndTable = new ImageOfFurniture(
  furnitureStyle02ImageSrc,
  { width: 347.35, height: 162.96 },
  { startRatio: 0.06, endRatio: 0.93 },
  0.32
);

const LoungerWithCactus = new ImageOfFurniture(
  furnitureStyle03ImageSrc,
  { width: 345.24, height: 232.84 },
  { startRatio: 0.06, endRatio: 0.95 },
  0.24
);

const DoubleChair = new ImageOfFurniture(
  furnitureStyle04ImageSrc,
  { width: 208.31, height: 173.86 },
  { startRatio: 0.33, endRatio: 0.81 },
  0.35
);

const BlackFauteuilWithPixarLamp = new ImageOfFurniture(
  furnitureStyle05ImageSrc,
  { width: 319.9, height: 225.7 },
  { startRatio: 0.06, endRatio: 0.92 },
  0.26
);

/**
 * Get the furniture that matches the tile best
 * This matching was done by our interior designer
 * @param {Number} tileCode
 * @returns {ImageOfFurniture} Image of the furniture
 */
export const getFurnitureForTileCode = (tileCode) => {
  switch (tileCode) {

    // Abalone
    case 70310:
      return DoubleChair;
    // Shell White
    case 70311:
      return leatherSofaTechnoLamp;
    // Deep Ocean
    case 70312:
      return WoodenChairAndTable;
    // Vintage Blush
    case 70313:
      return leatherSofaTechnoLamp;
    // Blanched Almond

    // Abalone
    case 70320:
      return DoubleChair;
    // Shell White
    case 70321:
      return leatherSofaTechnoLamp;
    // Deep Ocean
    case 70322:
      return WoodenChairAndTable;
    // Vintage Blush
    case 70323:
      return leatherSofaTechnoLamp;
    // Blanched Almond

    // Camber

    // Olive valley
    case 33730:
      return DoubleChair;
    // Shell White
    case 33731:
      return leatherSofaTechnoLamp;
    // Deep Ocean
    case 33732:
      return WoodenChairAndTable;
    // Vintage Blush
    case 33733:
      return leatherSofaTechnoLamp;
    // Blanched Almond
    case 33734:
      return leatherSofaTechnoLamp;

    // Cubic

    // Champagne Cocktail
    case 33700:
      return LoungerWithCactus;
    // Ever Green
    case 33701:
      return LoungerWithCactus;
    // Burnt Sienna
    case 33702:
      return LoungerWithCactus;

    // Helix

    // Ginger Roots
    case 33720:
      return BlackFauteuilWithPixarLamp;
    // Inca Gold
    case 33721:
      return BlackFauteuilWithPixarLamp;
    // Glazed Sage
    case 33722:
      return BlackFauteuilWithPixarLamp;

    // Prisma

    // Dove Grey
    case 33710:
      return BlackFauteuilWithPixarLamp;
    // Hot Chocolate
    case 33711:
      return BlackFauteuilWithPixarLamp;
    // Spiced Honey
    case 33712:
      return WoodenChairAndTable;
    // Venetian Red
    case 33713:
      return WoodenChairAndTable;

    default:
      return undefined;
  }
};
