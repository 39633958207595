// Camber tile resources
import CamberBW from "resources/images/tiles/camber/Samal_Camber.png";
import CamberDimensions from "resources/images/tiles/camber/Samal_Camber_Dimensions.png";
import CamberSchematic from "resources/images/tiles/camber/wallFilling/Samal_Camber_Wall_Schematic.png";
import CamberScene from "resources/images/tiles/camber/renders/Samal_Camber_scene.png";
import Camber33730 from "resources/images/tiles/camber/Samal_Camber_33730_F.png";
import Camber33730Wall from "resources/images/tiles/camber/wallFilling/Samal_Camber_33730_Wall.png";
import Camber33730Downloadable from "resources/images/tiles/camber/downloadables/Samal_Camber_33730_F_Downloadable.png";
import Camber33731 from "resources/images/tiles/camber/Samal_Camber_33731_F.png";
import Camber33731Wall from "resources/images/tiles/camber/wallFilling/Samal_Camber_33731_Wall.png";
import Camber33731Downloadable from "resources/images/tiles/camber/downloadables/Samal_Camber_33731_F_Downloadable.png";
import Camber33732 from "resources/images/tiles/camber/Samal_Camber_33732_F.png";
import Camber33732Wall from "resources/images/tiles/camber/wallFilling/Samal_Camber_33732_Wall.png";
import Camber33732Downloadable from "resources/images/tiles/camber/downloadables/Samal_Camber_33732_F_Downloadable.png";
import Camber33733 from "resources/images/tiles/camber/Samal_Camber_33733_F.png";
import Camber33733Wall from "resources/images/tiles/camber/wallFilling/Samal_Camber_33733_Wall.png";
import Camber33733Downloadable from "resources/images/tiles/camber/downloadables/Samal_Camber_33733_F_Downloadable.png";
import Camber33734 from "resources/images/tiles/camber/Samal_Camber_33734_F.png";
import Camber33734Wall from "resources/images/tiles/camber/wallFilling/Samal_Camber_33734_Wall.png";
import Camber33734Downloadable from "resources/images/tiles/camber/downloadables/Samal_Camber_33734_F_Downloadable.png";

// Camber resources for the images we display in step 3
import Camber33730WallFilling from "resources/images/tiles/camber/renders/Samal_Camber_33730_Wall.png";
import Camber33730Composition from "resources/images/tiles/camber/renders/Samal_Camber_33730_Composition.png";
import Camber33731WallFilling from "resources/images/tiles/camber/renders/Samal_Camber_33731_Wall.png";
import Camber33731Composition from "resources/images/tiles/camber/renders/Samal_Camber_33731_Composition.png";
import Camber33732WallFilling from "resources/images/tiles/camber/renders/Samal_Camber_33732_Wall.png";
import Camber33732Composition from "resources/images/tiles/camber/renders/Samal_Camber_33732_Composition.png";
import Camber33733WallFilling from "resources/images/tiles/camber/renders/Samal_Camber_33733_Wall.png";
import Camber33733Composition from "resources/images/tiles/camber/renders/Samal_Camber_33733_Composition.png";
import Camber33734WallFilling from "resources/images/tiles/camber/renders/Samal_Camber_33734_Wall.png";
import Camber33734Composition from "resources/images/tiles/camber/renders/Samal_Camber_33734_Composition.png";

// Camber decorative resources
import CamberDecorative1Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_Schematic.png";
import CamberDecorative1_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_33730.png";
import CamberDecorative1_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_33731.png";
import CamberDecorative1_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_33732.png";
import CamberDecorative1_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_33733.png";
import CamberDecorative1_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_1_33734.png";

import CamberDecorative2Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_Schematic.png";
import CamberDecorative2_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_33730.png";
import CamberDecorative2_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_33731.png";
import CamberDecorative2_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_33732.png";
import CamberDecorative2_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_33733.png";
import CamberDecorative2_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_2_33734.png";

import CamberDecorative3Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_Schematic.png";
import CamberDecorative3_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_33730.png";
import CamberDecorative3_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_33731.png";
import CamberDecorative3_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_33732.png";
import CamberDecorative3_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_33733.png";
import CamberDecorative3_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_3_33734.png";

import CamberDecorative4Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_Schematic.png";
import CamberDecorative4_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_33730.png";
import CamberDecorative4_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_33731.png";
import CamberDecorative4_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_33732.png";
import CamberDecorative4_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_33733.png";
import CamberDecorative4_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_4_33734.png";

import CamberDecorative5Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_Schematic.png";
import CamberDecorative5_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_33730.png";
import CamberDecorative5_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_33731.png";
import CamberDecorative5_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_33732.png";
import CamberDecorative5_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_33733.png";
import CamberDecorative5_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_5_33734.png";

import CamberDecorative6Schematic from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_Schematic.png";
import CamberDecorative6_33730 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_33730.png";
import CamberDecorative6_33731 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_33731.png";
import CamberDecorative6_33732 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_33732.png";
import CamberDecorative6_33733 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_33733.png";
import CamberDecorative6_33734 from "resources/images/tiles/camber/decoratives/Samal_Camber_Decorative_6_33734.png";

// Cubic tile resources
import CubicBW from "resources/images/tiles/cubic/Samal_Cubic.png";
import CubicDimensions from "resources/images/tiles/cubic/Samal_Cubic_Dimensions.png";
import CubicSchematic from "resources/images/tiles/cubic/wallFilling/Samal_Cubic_Wall_Schematic.png";
import CubicScene from "resources/images/tiles/cubic/renders/Samal_Cubic_scene.png";
import Cubic33700 from "resources/images/tiles/cubic/Samal_Cubic_33700_F.png";
import Cubic33700Wall from "resources/images/tiles/cubic/wallFilling/Samal_Cubic_33700_Wall.png";
import Cubic33700Downloadable from "resources/images/tiles/cubic/downloadables/Samal_Cubic_33700_F_Downloadable.png";
import Cubic33701 from "resources/images/tiles/cubic/Samal_Cubic_33701_F.png";
import Cubic33701Wall from "resources/images/tiles/cubic/wallFilling/Samal_Cubic_33701_Wall.png";
import Cubic33701Downloadable from "resources/images/tiles/cubic/downloadables/Samal_Cubic_33701_F_Downloadable.png";
import Cubic33702 from "resources/images/tiles/cubic/Samal_Cubic_33702_F.png";
import Cubic33702Wall from "resources/images/tiles/cubic/wallFilling/Samal_Cubic_33702_Wall.png";
import Cubic33702Downloadable from "resources/images/tiles/cubic/downloadables/Samal_Cubic_33702_F_Downloadable.png";

// Cubic resources for the images we display in step 3
import Cubic33700WallFilling from "resources/images/tiles/cubic/renders/Samal_Cubic_33700_Wall.png";
import Cubic33700Composition from "resources/images/tiles/cubic/renders/Samal_Cubic_33700_Composition.png";
import Cubic33701WallFilling from "resources/images/tiles/cubic/renders/Samal_Cubic_33701_Wall.png";
import Cubic33701Composition from "resources/images/tiles/cubic/renders/Samal_Cubic_33701_Composition.png";
import Cubic33702WallFilling from "resources/images/tiles/cubic/renders/Samal_Cubic_33702_Wall.png";
import Cubic33702Composition from "resources/images/tiles/cubic/renders/Samal_Cubic_33702_Composition.png";

// Cubic decorative resources
import CubicDecorative1Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_1_Schematic.png";
import CubicDecorative1_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_1_33700.png";
import CubicDecorative1_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_1_33701.png";
import CubicDecorative1_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_1_33702.png";

import CubicDecorative2Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_2_Schematic.png";
import CubicDecorative2_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_2_33700.png";
import CubicDecorative2_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_2_33701.png";
import CubicDecorative2_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_2_33702.png";

import CubicDecorative3Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_3_Schematic.png";
import CubicDecorative3_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_3_33700.png";
import CubicDecorative3_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_3_33701.png";
import CubicDecorative3_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_3_33702.png";

import CubicDecorative4Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_4_Schematic.png";
import CubicDecorative4_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_4_33700.png";
import CubicDecorative4_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_4_33701.png";
import CubicDecorative4_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_4_33702.png";

import CubicDecorative5Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_5_Schematic.png";
import CubicDecorative5_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_5_33700.png";
import CubicDecorative5_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_5_33701.png";
import CubicDecorative5_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_5_33702.png";

import CubicDecorative6Schematic from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_6_Schematic.png";
import CubicDecorative6_33700 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_6_33700.png";
import CubicDecorative6_33701 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_6_33701.png";
import CubicDecorative6_33702 from "resources/images/tiles/cubic/decoratives/Samal_Cubic_Decorative_6_33702.png";

// Helix tile resources
import HelixBW from "resources/images/tiles/helix/Samal_Helix.png";
import HelixDimensions from "resources/images/tiles/helix/Samal_Helix_Dimensions.png";
import HelixSchematic from "resources/images/tiles/helix/wallFilling/Samal_Helix_Wall_Schematic.png";
import HelixScene from "resources/images/tiles/helix/renders/Samal_Helix_scene.png";
import Helix33720 from "resources/images/tiles/helix/Samal_Helix_33720_F.png";
import Helix33720Wall from "resources/images/tiles/helix/wallFilling/Samal_Helix_33720_Wall.png";
import Helix33720Downloadable from "resources/images/tiles/helix/downloadables/Samal_Helix_33720_F_Downloadable.png";
import Helix33721 from "resources/images/tiles/helix/Samal_Helix_33721_F.png";
import Helix33721Wall from "resources/images/tiles/helix/wallFilling/Samal_Helix_33721_Wall.png";
import Helix33721Downloadable from "resources/images/tiles/helix/downloadables/Samal_Helix_33721_F_Downloadable.png";
import Helix33722 from "resources/images/tiles/helix/Samal_Helix_33722_F.png";
import Helix33722Wall from "resources/images/tiles/helix/wallFilling/Samal_Helix_33722_Wall.png";
import Helix33722Downloadable from "resources/images/tiles/helix/downloadables/Samal_Helix_33722_F_Downloadable.png";

// Helix resources for the images we display in step 3
import Helix33720WallFilling from "resources/images/tiles/helix/renders/Samal_Helix_33720_Wall.png";
import Helix33720Composition from "resources/images/tiles/helix/renders/Samal_Helix_33720_Composition.png";
import Helix33721WallFilling from "resources/images/tiles/helix/renders/Samal_Helix_33721_Wall.png";
import Helix33721Composition from "resources/images/tiles/helix/renders/Samal_Helix_33721_Composition.png";
import Helix33722WallFilling from "resources/images/tiles/helix/renders/Samal_Helix_33722_Wall.png";
import Helix33722Composition from "resources/images/tiles/helix/renders/Samal_Helix_33722_Composition.png";

// Helix decorative resources
import HelixDecorative1Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_1_Schematic.png";
import HelixDecorative1_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_1_33720.png";
import HelixDecorative1_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_1_33721.png";
import HelixDecorative1_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_1_33722.png";

import HelixDecorative2Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_2_Schematic.png";
import HelixDecorative2_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_2_33720.png";
import HelixDecorative2_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_2_33721.png";
import HelixDecorative2_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_2_33722.png";

import HelixDecorative3Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_3_Schematic.png";
import HelixDecorative3_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_3_33720.png";
import HelixDecorative3_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_3_33721.png";
import HelixDecorative3_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_3_33722.png";

import HelixDecorative4Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_4_Schematic.png";
import HelixDecorative4_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_4_33720.png";
import HelixDecorative4_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_4_33721.png";
import HelixDecorative4_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_4_33722.png";

import HelixDecorative5Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_5_Schematic.png";
import HelixDecorative5_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_5_33720.png";
import HelixDecorative5_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_5_33721.png";
import HelixDecorative5_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_5_33722.png";

import HelixDecorative6Schematic from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_6_Schematic.png";
import HelixDecorative6_33720 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_6_33720.png";
import HelixDecorative6_33721 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_6_33721.png";
import HelixDecorative6_33722 from "resources/images/tiles/helix/decoratives/Samal_Helix_Decorative_6_33722.png";

// Prisma tile resources
import PrismaBW from "resources/images/tiles/prisma/Samal_Prisma.png";
import PrismaDimensions from "resources/images/tiles/prisma/Samal_Prisma_Dimensions.png";
import PrismaSchematic from "resources/images/tiles/prisma/wallFilling/Samal_Prisma_Wall_Schematic.png";
import PrismaScene from "resources/images/tiles/prisma/renders/Samal_Prisma_scene.png";
import Prisma33710 from "resources/images/tiles/prisma/Samal_Prisma_33710_F.png";
import Prisma33710Wall from "resources/images/tiles/prisma/wallFilling/Samal_Prisma_33710_Wall.png";
import Prisma33710Downloadable from "resources/images/tiles/prisma/downloadables/Samal_Prisma_33710_F_Downloadable.png";
import Prisma33711 from "resources/images/tiles/prisma/Samal_Prisma_33711_F.png";
import Prisma33711Wall from "resources/images/tiles/prisma/wallFilling/Samal_Prisma_33711_Wall.png";
import Prisma33711Downloadable from "resources/images/tiles/prisma/downloadables/Samal_Prisma_33711_F_Downloadable.png";
import Prisma33712 from "resources/images/tiles/prisma/Samal_Prisma_33712_F.png";
import Prisma33712Wall from "resources/images/tiles/prisma/wallFilling/Samal_Prisma_33712_Wall.png";
import Prisma33712Downloadable from "resources/images/tiles/prisma/downloadables/Samal_Prisma_33712_F_Downloadable.png";
import Prisma33713 from "resources/images/tiles/prisma/Samal_Prisma_33713_F.png";
import Prisma33713Wall from "resources/images/tiles/prisma/wallFilling/Samal_Prisma_33713_Wall.png";
import Prisma33713Downloadable from "resources/images/tiles/prisma/downloadables/Samal_Prisma_33713_F_Downloadable.png";

// Prisma resources for the images we display in step 3
import Prisma33710WallFilling from "resources/images/tiles/prisma/renders/Samal_Prisma_33710_Wall.png";
import Prisma33710Composition from "resources/images/tiles/prisma/renders/Samal_Prisma_33710_Composition.png";
import Prisma33711WallFilling from "resources/images/tiles/prisma/renders/Samal_Prisma_33711_Wall.png";
import Prisma33711Composition from "resources/images/tiles/prisma/renders/Samal_Prisma_33711_Composition.png";
import Prisma33712WallFilling from "resources/images/tiles/prisma/renders/Samal_Prisma_33712_Wall.png";
import Prisma33712Composition from "resources/images/tiles/prisma/renders/Samal_Prisma_33712_Composition.png";
import Prisma33713WallFilling from "resources/images/tiles/prisma/renders/Samal_Prisma_33713_Wall.png";
import Prisma33713Composition from "resources/images/tiles/prisma/renders/Samal_Prisma_33713_Composition.png";

// Prisma decorative resources
import PrismaDecorative1Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_1_Schematic.png";
import PrismaDecorative1_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_1_33710.png";
import PrismaDecorative1_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_1_33711.png";
import PrismaDecorative1_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_1_33712.png";
import PrismaDecorative1_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_1_33713.png";

import PrismaDecorative2Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_2_Schematic.png";
import PrismaDecorative2_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_2_33710.png";
import PrismaDecorative2_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_2_33711.png";
import PrismaDecorative2_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_2_33712.png";
import PrismaDecorative2_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_2_33713.png";

import PrismaDecorative3Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_3_Schematic.png";
import PrismaDecorative3_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_3_33710.png";
import PrismaDecorative3_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_3_33711.png";
import PrismaDecorative3_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_3_33712.png";
import PrismaDecorative3_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_3_33713.png";

import PrismaDecorative4Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_4_Schematic.png";
import PrismaDecorative4_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_4_33710.png";
import PrismaDecorative4_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_4_33711.png";
import PrismaDecorative4_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_4_33712.png";
import PrismaDecorative4_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_4_33713.png";

import PrismaDecorative5Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_5_Schematic.png";
import PrismaDecorative5_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_5_33710.png";
import PrismaDecorative5_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_5_33711.png";
import PrismaDecorative5_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_5_33712.png";
import PrismaDecorative5_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_5_33713.png";

import PrismaDecorative6Schematic from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_6_Schematic.png";
import PrismaDecorative6_33710 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_6_33710.png";
import PrismaDecorative6_33711 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_6_33711.png";
import PrismaDecorative6_33712 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_6_33712.png";
import PrismaDecorative6_33713 from "resources/images/tiles/prisma/decoratives/Samal_Prisma_Decorative_6_33713.png";

import Abalone from "resources/images/tiles/abalone/Luster_Abalone.png";
import Abalone70320 from "resources/images/tiles/abalone/Luster_Abalone_70320.png";
import Abalone70321 from "resources/images/tiles/abalone/Luster_Abalone_70321.png";
import Abalone70322 from "resources/images/tiles/abalone/Luster_Abalone_70322.png";
import Abalone70323 from "resources/images/tiles/abalone/Luster_Abalone_70323.png";

import AbaloneRoomshot70321 from "resources/images/tiles/abalone/renders/Luster_Abalone_70321_Roomshot.png";
import AbaloneDimensions from "resources/images/tiles/abalone/AbaloneDimensions.png";

import AbaloneSchematic from "resources/images/tiles/abalone/wallfilling/wallFilling_Abalone_Schematic.png";
import AbaloneWallFilling70320 from "resources/images/tiles/abalone/wallfilling/WallFilling_70320.png";
import AbaloneWallFilling70321 from "resources/images/tiles/abalone/wallfilling/WallFilling_70321.png";
import AbaloneWallFilling70322 from "resources/images/tiles/abalone/wallfilling/WallFilling_70322.png";
import AbaloneWallFilling70323 from "resources/images/tiles/abalone/wallfilling/WallFilling_70323.png";

// LUCEA
import Lucea from "resources/images/tiles/lucea/Luster_Lucea.png";
import Lucea70310 from "resources/images/tiles/lucea/Luster_Lucea_70310.png";
import Lucea70311 from "resources/images/tiles/lucea/Luster_Lucea_70311.png";
import Lucea70312 from "resources/images/tiles/lucea/Luster_Lucea_70312.png";
import Lucea70313 from "resources/images/tiles/lucea/Luster_Lucea_70313.png";

import LuceaRoomshot70313 from "resources/images/tiles/lucea/renders/Luster_Lucea_70313_Roomshot.png";
import LuceaDimensions from "resources/images/tiles/lucea/LuceaDimensions.png";

import LuceaSchematic from "resources/images/tiles/lucea/wallfilling/wallFilling_Lucea_Schematic.png";
import LuceaWallFilling70310 from "resources/images/tiles/lucea/wallfilling/WallFilling_70310.png";
import LuceaWallFilling70311 from "resources/images/tiles/lucea/wallfilling/WallFilling_70311.png";
import LuceaWallFilling70312 from "resources/images/tiles/lucea/wallfilling/WallFilling_70312.png";
import LuceaWallFilling70313 from "resources/images/tiles/lucea/wallfilling/WallFilling_70313.png";

import UnitOfLengths from "classes/UnitOfLengths";

export const tiles = [
  {
    name: "Abalone",
    collection: "Luster",
    url: Abalone,
    scene: AbaloneRoomshot70321,
    schematicWall: AbaloneSchematic,
    tileDimensionsImage: AbaloneDimensions,
    tileDimensions: {
      width: 470,
      height: 480,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 132, height: 102 }, // 285 - 26 = 259
    soldPer: 4,
    tileColorVariants: [
      {
        code: 70320,
        name: "Pearl",
        url: Abalone70320,
        wallUrl: AbaloneWallFilling70320,
        wallFillingRenderUrl: AbaloneWallFilling70320,
        downloadUrl: Abalone70320,
        downloadName: "luster-abalone-70320",
      },
      {
        code: 70321,
        name: "Coquille",
        url: Abalone70321,
        wallUrl: AbaloneWallFilling70321,
        wallFillingRenderUrl: AbaloneWallFilling70321,
        downloadUrl: Abalone70321,
        downloadName: "luster-abalone-70321",
      },
      {
        code: 70322,
        name: "Mocha",
        url: Abalone70322,
        wallUrl: AbaloneWallFilling70322,
        wallFillingRenderUrl: AbaloneWallFilling70322,
        downloadUrl: Abalone70322,
        downloadName: "luster-abalone-70322",
      },
      {
        code: 70323,
        name: "Riverbank",
        url: Abalone70323,
        wallUrl: AbaloneWallFilling70323,
        wallFillingRenderUrl: AbaloneWallFilling70323,
        downloadUrl: Abalone70323,
        downloadName: "luster-abalone-70323",
      },
    ]
  },
  {
    name: "Lucea",
    collection: "Luster",
    url: Lucea,
    scene: LuceaRoomshot70313,
    schematicWall: LuceaSchematic,
    tileDimensionsImage: LuceaDimensions,
    tileDimensions: {
      width: 480,
      height: 490,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 132, height: 102 }, // 285 - 26 = 259
    soldPer: 4,
    tileColorVariants: [
      {
        code: 70310,
        name: "Pearl",
        url: Lucea70310,
        wallUrl: LuceaWallFilling70310,
        wallFillingRenderUrl: LuceaWallFilling70310,
        downloadUrl: Lucea70310,
        downloadName: "luster-lucea-70310",
      },
      {
        code: 70311,
        name: "Coquille",
        url: Lucea70311,
        wallUrl: LuceaWallFilling70311,
        wallFillingRenderUrl: LuceaWallFilling70311,
        downloadUrl: Lucea70311,
        downloadName: "luster-lucea-70311",
      },
      {
        code: 70312,
        name: "Seagrass",
        url: Lucea70312,
        wallUrl: LuceaWallFilling70312,
        wallFillingRenderUrl: LuceaWallFilling70312,
        downloadUrl: Lucea70312,
        downloadName: "luster-lucea-70312",
      },
      {
        code: 70313,
        name: "Marigold",
        url: Lucea70313,
        wallUrl: LuceaWallFilling70313,
        wallFillingRenderUrl: LuceaWallFilling70313,
        downloadUrl: Lucea70313,
        downloadName: "luster-lucea-70313",
      },
    ]
  },
  {
    name: "Camber",
    url: CamberBW,
    scene: CamberScene,
    schematicWall: CamberSchematic,
    tileDimensionsImage: CamberDimensions,
    tileDimensions: {
      width: 460,
      height: 337,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 132, height: 102 },
    soldPer: 5,
    tileColorVariants: [
      {
        code: 33730,
        name: "Olive Valley",
        url: Camber33730,
        wallUrl: Camber33730Wall,
        wallFillingRenderUrl: Camber33730WallFilling,
        wallDecorativeRenderUrl: Camber33730Composition,
        downloadUrl: Camber33730Downloadable,
        downloadName: "samal-camber-33730",
      },
      {
        code: 33731,
        name: "Shell White",
        url: Camber33731,
        wallUrl: Camber33731Wall,
        wallFillingRenderUrl: Camber33731WallFilling,
        wallDecorativeRenderUrl: Camber33731Composition,
        downloadUrl: Camber33731Downloadable,
        downloadName: "samal-camber-33731",
      },
      {
        code: 33732,
        name: "Deep Ocean",
        url: Camber33732,
        wallUrl: Camber33732Wall,
        wallFillingRenderUrl: Camber33732WallFilling,
        wallDecorativeRenderUrl: Camber33732Composition,
        downloadUrl: Camber33732Downloadable,
        downloadName: "samal-camber-33732",
      },
      {
        code: 33733,
        name: "Vintage Blush",
        url: Camber33733,
        wallUrl: Camber33733Wall,
        wallFillingRenderUrl: Camber33733WallFilling,
        wallDecorativeRenderUrl: Camber33733Composition,
        downloadUrl: Camber33733Downloadable,
        downloadName: "samal-camber-33733",
      },
      {
        code: 33734,
        name: "Blanched Almond",
        url: Camber33734,
        wallUrl: Camber33734Wall,
        wallFillingRenderUrl: Camber33734WallFilling,
        wallDecorativeRenderUrl: Camber33734Composition,
        downloadUrl: Camber33734Downloadable,
        downloadName: "samal-camber-33734",
      },
    ],
    decorativeElements: [
      {
        dimensions: {
          width: 457,
          height: 102,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 30,
        schematic: CamberDecorative1Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative1_33730,
          },
          {
            code: 33731,
            url: CamberDecorative1_33731,
          },
          {
            code: 33732,
            url: CamberDecorative1_33732,
          },
          {
            code: 33733,
            url: CamberDecorative1_33733,
          },
          {
            code: 33734,
            url: CamberDecorative1_33734,
          },
        ],
      },
      {
        dimensions: {
          width: 130,
          height: 130,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CamberDecorative2Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative2_33730,
          },
          {
            code: 33731,
            url: CamberDecorative2_33731,
          },
          {
            code: 33732,
            url: CamberDecorative2_33732,
          },
          {
            code: 33733,
            url: CamberDecorative2_33733,
          },
          {
            code: 33734,
            url: CamberDecorative2_33734,
          },
        ],
      },
      {
        dimensions: {
          width: 227,
          height: 61,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CamberDecorative3Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative3_33730,
          },
          {
            code: 33731,
            url: CamberDecorative3_33731,
          },
          {
            code: 33732,
            url: CamberDecorative3_33732,
          },
          {
            code: 33733,
            url: CamberDecorative3_33733,
          },
          {
            code: 33734,
            url: CamberDecorative3_33734,
          },
        ],
      },
      {
        dimensions: {
          width: 41,
          height: 168.3,
          horizontalParts: 3,
          horizontalGap: 13.1,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 15,
        schematic: CamberDecorative4Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative4_33730,
          },
          {
            code: 33731,
            url: CamberDecorative4_33731,
          },
          {
            code: 33732,
            url: CamberDecorative4_33732,
          },
          {
            code: 33733,
            url: CamberDecorative4_33733,
          },
          {
            code: 33734,
            url: CamberDecorative4_33734,
          },
        ],
      },
      {
        dimensions: {
          width: 235,
          height: 117.2,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CamberDecorative5Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative5_33730,
          },
          {
            code: 33731,
            url: CamberDecorative5_33731,
          },
          {
            code: 33732,
            url: CamberDecorative5_33732,
          },
          {
            code: 33733,
            url: CamberDecorative5_33733,
          },
          {
            code: 33734,
            url: CamberDecorative5_33734,
          },
        ],
      },
      {
        dimensions: {
          width: 176.8,
          height: 125.9,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CamberDecorative6Schematic,
        colorVariants: [
          {
            code: 33730,
            url: CamberDecorative6_33730,
          },
          {
            code: 33731,
            url: CamberDecorative6_33731,
          },
          {
            code: 33732,
            url: CamberDecorative6_33732,
          },
          {
            code: 33733,
            url: CamberDecorative6_33733,
          },
          {
            code: 33734,
            url: CamberDecorative6_33734,
          },
        ],
      },
    ],
  },
  {
    name: "Cubic",
    url: CubicBW,
    scene: CubicScene,
    schematicWall: CubicSchematic,
    tileDimensionsImage: CubicDimensions,
    tileDimensions: {
      width: 455,
      height: 394,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 132, height: 102 },
    soldPer: 5,
    tileColorVariants: [
      {
        code: 33700,
        name: "Champagne Cocktail",
        url: Cubic33700,
        wallUrl: Cubic33700Wall,
        wallFillingRenderUrl: Cubic33700WallFilling,
        wallDecorativeRenderUrl: Cubic33700Composition,
        downloadUrl: Cubic33700Downloadable,
        downloadName: "samal-cubic-33700",
      },
      {
        code: 33701,
        name: "Ever Green",
        url: Cubic33701,
        wallUrl: Cubic33701Wall,
        wallFillingRenderUrl: Cubic33701WallFilling,
        wallDecorativeRenderUrl: Cubic33701Composition,
        downloadUrl: Cubic33701Downloadable,
        downloadName: "samal-cubic-33701",
      },
      {
        code: 33702,
        name: "Burnt Sienna",
        url: Cubic33702,
        wallUrl: Cubic33702Wall,
        wallFillingRenderUrl: Cubic33702WallFilling,
        wallDecorativeRenderUrl: Cubic33702Composition,
        downloadUrl: Cubic33702Downloadable,
        downloadName: "samal-cubic-33702",
      },
    ],
    decorativeElements: [
      {
        dimensions: {
          width: 268,
          height: 137,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        extraDescription: "extraCubicDescription",
        schematic: CubicDecorative1Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative1_33700,
          },
          {
            code: 33701,
            url: CubicDecorative1_33701,
          },
          {
            code: 33702,
            url: CubicDecorative1_33702,
          },
        ],
      },
      {
        dimensions: {
          width: 267,
          height: 300,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 30,
        schematic: CubicDecorative2Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative2_33700,
          },
          {
            code: 33701,
            url: CubicDecorative2_33701,
          },
          {
            code: 33702,
            url: CubicDecorative2_33702,
          },
        ],
      },
      {
        dimensions: {
          width: 231.5,
          height: 135.2,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CubicDecorative3Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative3_33700,
          },
          {
            code: 33701,
            url: CubicDecorative3_33701,
          },
          {
            code: 33702,
            url: CubicDecorative3_33702,
          },
        ],
      },
      {
        dimensions: {
          width: 45.8,
          height: 196.1,
          horizontalParts: 3,
          horizontalGap: 15.7,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 15,
        schematic: CubicDecorative4Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative4_33700,
          },
          {
            code: 33701,
            url: CubicDecorative4_33701,
          },
          {
            code: 33702,
            url: CubicDecorative4_33702,
          },
        ],
      },
      {
        dimensions: {
          width: 234.8,
          height: 122.2,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CubicDecorative5Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative5_33700,
          },
          {
            code: 33701,
            url: CubicDecorative5_33701,
          },
          {
            code: 33702,
            url: CubicDecorative5_33702,
          },
        ],
      },
      {
        dimensions: {
          width: 182.6,
          height: 121.4,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: CubicDecorative6Schematic,
        colorVariants: [
          {
            code: 33700,
            url: CubicDecorative6_33700,
          },
          {
            code: 33701,
            url: CubicDecorative6_33701,
          },
          {
            code: 33702,
            url: CubicDecorative6_33702,
          },
        ],
      },
    ],
  },
  {
    name: "Helix",
    url: HelixBW,
    scene: HelixScene,
    schematicWall: HelixSchematic,
    tileDimensionsImage: HelixDimensions,
    tileDimensions: {
      width: 246,
      height: 284,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 86, height: 109 },
    soldPer: 10,
    tileColorVariants: [
      {
        code: 33720,
        name: "Ginger Roots",
        url: Helix33720,
        wallUrl: Helix33720Wall,
        wallFillingRenderUrl: Helix33720WallFilling,
        wallDecorativeRenderUrl: Helix33720Composition,
        downloadUrl: Helix33720Downloadable,
        downloadName: "samal-helix-33720",
      },
      {
        code: 33721,
        name: "Inca Gold",
        url: Helix33721,
        wallUrl: Helix33721Wall,
        wallFillingRenderUrl: Helix33721WallFilling,
        wallDecorativeRenderUrl: Helix33721Composition,
        downloadUrl: Helix33721Downloadable,
        downloadName: "samal-helix-33721",
      },
      {
        code: 33722,
        name: "Glazed Sage",
        url: Helix33722,
        wallUrl: Helix33722Wall,
        wallFillingRenderUrl: Helix33722WallFilling,
        wallDecorativeRenderUrl: Helix33722Composition,
        downloadUrl: Helix33722Downloadable,
        downloadName: "samal-helix-33722",
      },
    ],
    decorativeElements: [
      {
        dimensions: {
          width: 388,
          height: 200,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 60,
        schematic: HelixDecorative1Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative1_33720,
          },
          {
            code: 33721,
            url: HelixDecorative1_33721,
          },
          {
            code: 33722,
            url: HelixDecorative1_33722,
          },
        ],
      },
      {
        dimensions: {
          width: 277,
          height: 181,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 40,
        schematic: HelixDecorative2Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative2_33720,
          },
          {
            code: 33721,
            url: HelixDecorative2_33721,
          },
          {
            code: 33722,
            url: HelixDecorative2_33722,
          },
        ],
      },
      {
        dimensions: {
          width: 128.8,
          height: 123,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 20,
        schematic: HelixDecorative3Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative3_33720,
          },
          {
            code: 33721,
            url: HelixDecorative3_33721,
          },
          {
            code: 33722,
            url: HelixDecorative3_33722,
          },
        ],
      },
      {
        dimensions: {
          width: 99.7,
          height: 142,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 20,
        schematic: HelixDecorative4Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative4_33720,
          },
          {
            code: 33721,
            url: HelixDecorative4_33721,
          },
          {
            code: 33722,
            url: HelixDecorative4_33722,
          },
        ],
      },
      {
        dimensions: {
          width: 168,
          height: 180,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 20,
        schematic: HelixDecorative5Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative5_33720,
          },
          {
            code: 33721,
            url: HelixDecorative5_33721,
          },
          {
            code: 33722,
            url: HelixDecorative5_33722,
          },
        ],
      },
      {
        dimensions: {
          width: 25,
          height: 283,
          horizontalParts: 3,
          horizontalGap: 39.4,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 30,
        schematic: HelixDecorative6Schematic,
        colorVariants: [
          {
            code: 33720,
            url: HelixDecorative6_33720,
          },
          {
            code: 33721,
            url: HelixDecorative6_33721,
          },
          {
            code: 33722,
            url: HelixDecorative6_33722,
          },
        ],
      },
    ],
  },
  {
    name: "Prisma",
    url: PrismaBW,
    scene: PrismaScene,
    schematicWall: PrismaSchematic,
    tileDimensionsImage: PrismaDimensions,
    tileDimensions: {
      width: 400,
      height: 421,
      unitOfLength: UnitOfLengths.millimeters,
    },
    previewSize: { width: 96, height: 96 },
    soldPer: 5,
    tileColorVariants: [
      {
        code: 33710,
        name: "Dove Grey",
        url: Prisma33710,
        wallUrl: Prisma33710Wall,
        wallFillingRenderUrl: Prisma33710WallFilling,
        wallDecorativeRenderUrl: Prisma33710Composition,
        downloadUrl: Prisma33710Downloadable,
        downloadName: "samal-prisma-33710",
      },
      {
        code: 33711,
        name: "Hot Chocolate",
        url: Prisma33711,
        wallUrl: Prisma33711Wall,
        wallFillingRenderUrl: Prisma33711WallFilling,
        wallDecorativeRenderUrl: Prisma33711Composition,
        downloadUrl: Prisma33711Downloadable,
        downloadName: "samal-prisma-33711",
      },
      {
        code: 33712,
        name: "Spiced Honey",
        url: Prisma33712,
        wallUrl: Prisma33712Wall,
        wallFillingRenderUrl: Prisma33712WallFilling,
        wallDecorativeRenderUrl: Prisma33712Composition,
        downloadUrl: Prisma33712Downloadable,
        downloadName: "samal-prisma-33712",
      },
      {
        code: 33713,
        name: "Venetian Red",
        url: Prisma33713,
        wallUrl: Prisma33713Wall,
        wallFillingRenderUrl: Prisma33713WallFilling,
        wallDecorativeRenderUrl: Prisma33713Composition,
        downloadUrl: Prisma33713Downloadable,
        downloadName: "samal-prisma-33713",
      },
    ],
    decorativeElements: [
      {
        dimensions: {
          width: 308,
          height: 172,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 20,
        schematic: PrismaDecorative1Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative1_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative1_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative1_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative1_33713,
          },
        ],
      },
      {
        dimensions: {
          width: 480,
          height: 300,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 40,
        schematic: PrismaDecorative2Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative2_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative2_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative2_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative2_33713,
          },
        ],
      },
      {
        dimensions: {
          width: 40.5,
          height: 209.1,
          horizontalParts: 3,
          horizontalGap: 12.8,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 15,
        schematic: PrismaDecorative3Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative3_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative3_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative3_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative3_33713,
          },
        ],
      },
      {
        dimensions: {
          width: 167.1,
          height: 208.5,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 10,
        schematic: PrismaDecorative4Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative4_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative4_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative4_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative4_33713,
          },
        ],
      },
      {
        dimensions: {
          width: 400,
          height: 84,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 20,
        schematic: PrismaDecorative5Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative5_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative5_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative5_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative5_33713,
          },
        ],
      },
      {
        dimensions: {
          width: 296,
          height: 102.7,
          unitOfLength: UnitOfLengths.centimeters,
        },
        amountOfTilesNeeded: 25,
        schematic: PrismaDecorative6Schematic,
        colorVariants: [
          {
            code: 33710,
            url: PrismaDecorative6_33710,
          },
          {
            code: 33711,
            url: PrismaDecorative6_33711,
          },
          {
            code: 33712,
            url: PrismaDecorative6_33712,
          },
          {
            code: 33713,
            url: PrismaDecorative6_33713,
          },
        ],
      },
    ],
  },
];

export const collectionCode = "21-3687";
